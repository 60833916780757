<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Services</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="/">Home</a></li>
                                            <li class="text-white active">Services</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Start Services Top -->
        <section class="section services-top">
            <div class="container">
                <div class="services-main">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <img src="assets/images/standard-quality-control-collage-concept.jpg" alt="service-tab.png" class="w-100 img-fluid">
                        </div>
                        <div class="col-md-6 mt-md-0 mt-4">
                            <div class="services-inner">
                                <div class="title-section">
                                    <div class="subtitle-hr d-flex align-items-center">
                                        <h6 class="subtitle theme-color text-uppercase position-relative">SERVICII</h6>
                                        <hr>
                                    </div>
                                    <h2 class="title text-md-left text-center f-40">La dispoziția dumneavoastră</h2>
                                </div>
                                <div class="flex-row align-items-center justify-content-start border0 p-2" >
                                    <div class="border0">
                                        <h4 class="green-color">1 </h4>
                                    </div>
                                    <div class="border0 left30" >
                                        <h6 class="text-secondary">Analiză software completă a proiectului</h6>
                                        <p class="font-weight-regular grey-text">
                                          Scopul analizei unui proiectului dvs de către un Product Manager calificat este de a asigura alinierea obiectivelor strategice ale produsului cu nevoile clientului (și eventual cu cerințele pieței), coordonând dezvoltarea și livrarea unei soluții de succes și valoroase.  </p>
                                    </div>
                                </div>
                                <div class="flex-row align-items-center justify-content-start border0 p-2">
                                    <div class="index-box mr-3 z-index">
                                        <h4 class="green-color"> 2 </h4>
                                    </div>
                                    <div class="left30">
                                        <h6 class="text-secondary">Wireframe</h6>
                                        <p class="font-weight-regular grey-text">
                                          Avantajele creării unui wireframe pentru o aplicație web includ clarificarea structurii și a funcționalităților, facilitarea comunicării între echipe și clienți, identificarea și rezolvarea rapidă a problemelor de design și navigare, contribuind la dezvoltarea unei aplicații unitare și eficiente. </p>
                                    </div>
                                </div>
                                <div class="flex-row align-items-center justify-content-start border0 p-2">
                                    <div class="index-box mr-5 z-index">
                                        <h4 class="green-color"> 3 </h4>
                                    </div>
                                    <div class="services-info z-index left30">
                                        <h6 class="text-secondary">Software Architect</h6>
                                        <p class="font-weight-regular grey-text">Misiunea Software Architect-ului este de a proiecta și defini arhitectura software optimă, asigurând scalabilitatea, securitatea și performanța sistemului în conformitate cu cerințele și standardele necesare. </p>
                                    </div>
                                </div>
                                <div class="flex-row align-items-center justify-content-start border0 p-2">
                                    <div class="index-box mr-3 z-index">
                                        <h4 class="green-color"> 4 </h4>
                                    </div>
                                    <div class="services-info z-index left30">
                                        <h6 class="text-secondary">Project Management && Development</h6>
                                        <p class="font-weight-regular grey-text">Avantajele managerizării proiectului dvs. de către un Project Manager include asigurarea unei planificări și coordonări eficiente a resurselor, gestionarea riscurilor, facilitarea comunicării intre membrii echipei de dezvoltare sau cu responsabilul clientului precum și menținerea obiectivelor pentru livrarea cu succes a proiectului. </p>
                                    </div>
                                </div>
                              <div class="flex-row align-items-center justify-content-start border0 p-2">
                                    <div class="index-box mr-3 z-index">
                                        <h4 class="green-color"> 5 </h4>
                                    </div>
                                    <div class="services-info z-index left30 ">
                                        <h6 class="text-secondary">Expertiză</h6>
                                        <p class="font-weight-regular grey-text">Specialiștii Inamed Consult au un experiență in domeniul software, telecomunicații, vanzări  business. Specialiștii nostri sunt certificați Prince 2 și ITIL și va pot oferi sfaturile necesare realizarii proiectelor în condițiile cele mai avantajoase legate de time to market, scalabilitate si preț. </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Services Top -->
        <!-- Start Aboutus Parallex -->
        <!-- End Aboutus Parallex -->
        <!-- Start Features section -->
        <section class="section">
            <div class="container">
                <div class="title-section">
                    <div class="subtitle-hr d-flex align-items-center">
                        <h6 class="subtitle theme-color text-uppercase position-relative">Features</h6>
                        <hr>
                    </div>
                    <h2 class="title text-md-left text-center f-40">Implementare proiecte</h2>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-sm-6">
                    <div class="mt-4">
                      <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="features-inner-text text-sm-left text-center">
                          <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                            <span class="icon transition mdi mdi-chart-areaspline d-flex align-items-center justify-content-center text-white f-40"></span>
                          </div>
                          <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Analiză</h5>
                          <p class="para-text text-sm-left text-center mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="mt-4">
                      <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="features-inner-text text-sm-left text-center">
                          <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                            <span class="icon transition mdi mdi-chart-areaspline d-flex align-items-center justify-content-center text-white f-40"></span>
                          </div>
                          <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Wireframe</h5>
                          <p class="para-text text-sm-left text-center mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-responsive d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Responsive Design</h5>
                                    <p class="para-text text-sm-left text-center mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-rocket d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Alegere Tehnologie</h5>
                                    <p class="para-text text-sm-left text-center mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="mt-4">
                      <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="features-inner-text text-sm-left text-center">
                          <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                            <span class="icon transition  mdi mdi-face-agent d-flex align-items-center justify-content-center text-white f-40"></span>
                          </div>
                          <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Project Management</h5>
                          <p class="para-text text-sm-left text-center mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-6">
                    <div class="mt-4">
                      <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="features-inner-text text-sm-left text-center">
                          <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                            <span class="icon transition  mdi mdi-face-agent d-flex align-items-center justify-content-center text-white f-40"></span>
                          </div>
                          <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Implementare soluție software</h5>
                          <p class="para-text text-sm-left text-center mb-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition mdi mdi-chat-processing d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Chatting Support Service</h5>
                                    <p class="para-text text-sm-left text-center mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>


                  <div class="col-lg-4 col-sm-6">
                        <div class="mt-4">
                            <div class="hover-block p-4 shadow wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="features-inner-text text-sm-left text-center">
                                    <div class="icon-block theme-bg d-inline-block rounded-circle p-3">
                                        <span class="icon transition  mdi mdi-face-agent d-flex align-items-center justify-content-center text-white f-40"></span>
                                    </div>
                                    <h5 class="my-3 theme-color text-sm-left text-center font-weight-medium">Instalare si suport</h5>
                                    <p class="para-text text-sm-left text-center mb-0"></p>
                                </div>
                            </div>
                        </div>
                  </div>


                </div>
            </div>
        </section>
        <!-- End Features section -->
    </div>
</template>
<script>
    //WOW JS
    import $ from "jquery";
    import "@/assets/css/style.css"
    import { WOW } from 'wowjs';
    export default {
        name: 'servicesComponent',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initWowAnimation();             
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            }
        }
    }
</script>

<style>

</style>
