<template>
    <div>
        <!-- Bra dCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">  
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Contact</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="/">Home</a></li>
                                            <li class="text-white active">Contact</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Google Map Section Start-->

        <!-- Google Map Section End-->
        <!-- Contact Section Start-->
        <section class="section section-contactus contactus-section">
            <div class="container">

                <div class="wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                    <div class="title-section">
                        <div class="subtitle-hr d-flex align-items-center">
                            <h6 class="subtitle theme-color text-uppercase position-relative">Contact</h6>
                            <hr>
                          <h3 class="text-dark res-h3-small">
                            <a href="mailto:office@inamedconsult.rom" class="text-dark">
                              office@inamedconsult.ro
                            </a>
                            <br>
                            <a href="tel:+40764 111 211" class="text-dark">Sales: +40764 111 211</a> <br>
                            <a href="tel:+40764 111 211" class="text-dark">Tech Sales: +40769 111 211</a>
                          </h3>
                        </div>

                    </div>
                    <div class="mt-4">
                        <div class="row">
                            <div class="text-center">
                              <div class="flex-row align-items-center flex-start">
                                <div class="green-color text-uppercase">Meet in our office in Empire Tower București

                                 <a class="text-dark left20" href="https://www.google.com/maps/place/Empire+Tower+-+Spatii+Birouri,+%C8%98oseaua+Iancului+31,+Bucure%C8%99ti+077085/@44.445907,26.1206053,6372m/data=!3m1!1e3!4m6!3m5!1s0x40b1f92a6571a5cf:0xa0635dea98321f87!8m2!3d44.4423858!4d26.142015!16s%2Fg%2F11bthxz93c?entry=ttu" target="_blank">
                                   <button class="btn btn-primary">Indicații de orientare</button>
                                 </a>
                                </div>
                              </div>

                              <div class="top30">
                                <a class="text-dark "   href="https://www.google.com/maps/place/Empire+Tower+-+Spatii+Birouri,+%C8%98oseaua+Iancului+31,+Bucure%C8%99ti+077085/@44.445907,26.1206053,6372m/data=!3m1!1e3!4m6!3m5!1s0x40b1f92a6571a5cf:0xa0635dea98321f87!8m2!3d44.4423858!4d26.142015!16s%2Fg%2F11bthxz93c?entry=ttu" target="_blank">
                                  <img style="max-width: 800px;" src="assets/images/iancului_31.png" alt="">
                                </a>
                              </div>
                            </div>
                        </div>
                      <div style="margin-top: 50px;">
                        <h2 class="green-color text-uppercase">Trimite mesaj</h2>
                      </div>
                    </div>
                    <form class="common-input-form mt-3">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input name="name" id="name" type="text" class="form-control border-0" placeholder="Your Name">
                                </div>
                                <div class="form-group">
                                    <input name="email" id="email" type="email" class="form-control border-0" placeholder="Your Email">
                                </div>
                                <div class="form-group">
                                    <input name="subject" id="subject" type="text" class="form-control border-0" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <textarea name="comments" id="comments" rows="4" class="form-control border-0" placeholder="Message"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="mt-2 btn theme-btn contact-form-btn border-0" value="Send">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <!-- Contact Section End-->
    </div>
</template>
<script>
    //WOW JS
    import $ from "jquery";
    import { WOW } from 'wowjs';
    export default {
        name: 'contactComponent',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initWowAnimation();              
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            } 
        }
    }
</script>