<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Login</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">Login</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Start Login -->
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-8">
                        <div class="form-card bg-white border p-4">
                            <form class="login-form common-input-form">
                                <div class="title-section">
                                    <h2 class="title text-left">Login</h2>
                                    <p class="grey-text font-weight-regular f-16">Sign in to your account to continue.</p>
                                </div>
                                <div class="form-body mt-3 mb-3">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label class="pb-1 grey-text">User Name</label>
                                                <div class="position-relative">
                                                    <i class="login-icon mdi mdi-account green-color"></i>
                                                    <input name="username" id="username" type="text" class="form-control" placeholder="User name...">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col">
                                                        <label class="pb-1 grey-text">Password</label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="text-sm-right">
                                                            <a href="#" class="grey-text font-weight-regular">Forgot Password ?</a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="position-relative">
                                                    <i class="login-icon mdi mdi-lock green-color"></i>
                                                    <input name="password" id="password" type="password" class="form-control" placeholder="Password...">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-12 text-center mt-3">
                                            <input type="submit" class="btn theme-dark-btn login-btn text-white w-100 text-uppercase" value="Log In">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="form-footer bg-white px-4 pt-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group text-center">
                                        <span class="grey-text font-weight-regular">Not registered?</span>
                                        <a href="register.html" class=" font-weight-regular">Create account</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Login -->
    </div>
</template>
<script>
import $ from "jquery";
import { WOW } from 'wowjs';
import {onMounted} from "vue";
    export default {
        name: 'LoginComponent',
        setup() {

          onMounted(()=>{
            init()
          })

            const init=()=> {
                initWowAnimation();
            }
            /*----WOW ANIMATION-----*/
            const initWowAnimation=()=>{
                var length = $('.wow').length;
                console.log(length)
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            }
        }
    }
</script>