<template>
    <!-- Start Header -->
    <header>
        <!--Start Navbar-->
        <nav class="navbar navbar-expand-lg navbar-inner fixed-top custom-nav sticky w-100 rounded-0 mb-0 border0" style="background-color: #0d6efd">
            <div class="menu-overlay"></div>
            <div class="container">
                <!-- LOGO -->
                <a class="navbar-brand brand-logo mr-4" href="/">
                       <span class="white" style="font-size:28px;"> INAMED CONSULT SRL</span>
<!--                    <img src="assets/images/logo.png" class="img-fluid logo-light" alt="">-->
<!--                    <img src="assets/images/logo-dark.png" class="img-fluid logo-dark" alt="">-->
                </a>
                <div class="navbar-collapse collapse justify-content-end mr-3" id="navbarCollapse">
                    <ul class="navbar-nav navbar-center">
                        <li class="nav-item active">
                            <router-link class="nav-link text-white transition text-uppercase" to="/">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link text-white transition text-uppercase" to="/about">Cum funcționează?</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link text-white transition text-uppercase" to="/services">Servicii</router-link>
                        </li>
                    </ul>
                </div>
                <div class="contact_btn">
                    <router-link class="btn theme-btn text-uppercase d-flex justify-content-center align-items-center" to="/contact">Contact</router-link>
                    <button class="navbar-toggler ml-2 p-0 mb-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="mdi mdi-menu"></i>
                    </button>
                </div>
            </div>
        </nav>
        <!-- End Navbar -->

    </header>
    <body>
    </body>
    <!-- End Header -->
</template>

<script>
    export default {
        name: "headerComponent",
        methods: {
        }
    }</script>