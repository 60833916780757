<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Cum funcționează?</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="/">Home</a></li>
                                            <li class="text-white active">Cum funcționează?</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Start Aboutus Top-->
        <section class="section about-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="title-section">
                            <h3>Doriți să dezvoltați un proiect IT?</h3>
                        </div>
                        <div class="para-text text-left mt-4" >
                            <div v-if="showMainTitlesProiect" >Product Manager-ul face analiza proiectului <span class="btn theme-btn theme-color" @click="setShowAnalizaProiect"> ... detalii </span>
                            </div>
                            <div v-if="showAnalizaProiect">
                              <h3>Ce este analiza unui proiect IT?</h3>
                              <p><span class="bolder">Înțelegerea nevoilor clienților și a pieței:</span> Product Managerul va colabora strâns cu clienții pentru a înțelege obiectivele și cerințele lor. Acesta va efectua cercetări pentru a înțelege piața și nevoile utilizatorilor finali.
                              </p>
                              <p><span class="bolder">Definirea strategiei produsului:</span> Bazat pe informațiile colectate, Product Managerul va dezvolta o strategie clară pentru produsul sau aplicația ce urmează a fi dezvoltată. Aceasta va cuprinde direcția generală, obiectivele, funcționalitățile principale și valorile unice ale produsului.
                              </p>
                              <p><span class="bolder">Elaborarea documentației și a cerințelor:</span> Product Managerul va crea documente detaliate precum specificațiile produsului, user stories, diagrame de flux și alte documente pentru a descrie în detaliu funcționalitățile și caracteristicile produsului.
                              </p>
                              <p><span class="bolder">Coordonarea cu echipele tehnice și non-tehnice:</span> Product Managerul va colabora cu echipele de dezvoltare, design și alte echipe implicate pentru a asigura înțelegerea și implementarea corectă a cerințelor.
                              </p>
                              <p><span class="bolder">Prioritizarea și gestionarea cerințelor:</span> Înțelegând prioritățile și resursele disponibile, Product Managerul va evalua și va prioritiza funcționalitățile sau caracteristicile produsului pentru a maximiza valoarea adusă clienților și utilizatorilor finali.
                              </p>
                              <p><span class="bolder">Monitorizarea și ajustarea:</span> Pe durata proiectului, Product Managerul va monitoriza progresul, va colecta feedback și va face ajustări în planul produsului, dacă este necesar, pentru a răspunde schimbărilor pieței sau cerințelor clienților.
                              </p>
                              <p><span class="bolder">Testarea și validarea:</span> Product Managerul va coordona testele și validările produsului pentru a se asigura că acesta respectă cerințele și este funcțional conform așteptărilor.
                              </p>
                              <span class="bolder">Comunicarea și raportarea:</span> Un aspect crucial este comunicarea eficientă cu toate părțile implicate, inclusiv cu clienții și echipele interne, pentru a menține transparența și pentru a asigura alinierea între toți factorii implicați în proiect.
                              <div class="btn theme-btn text-black" @click="setCloseAllDetailsProiect()"> (Close) </div>
                            </div>

                        </div>
                      <div class="para-text text-left mt-4">
                            <div v-if="showMainTitlesProiect">Software Arhitect-ul va crea un WIREFRAME care va aduce in fata clientului un mockup cu forma finală a proiectului <span class="btn theme-btn theme-color" @click="setShowWireframeProiect"> ... detalii </span>
                            </div>
                        <div v-if="showWireframeProiect">
                          <h3>De ce este necesar un wireframe?</h3>
                          <div class="btn theme-btn text-black" @click="setCloseAllDetailsProiect()"> ( Close ) </div>
                          <p>Wireframe-ul oferă o schiță rudimentară, permitând vizualizarea și înțelegerea conceptului final al aplicației.</p>

                          <h4>Clarificarea structurii și funcționalităților</h4>
                          <p>Wireframe-ul evidențiază structura și organizarea generală a interfeței, clarificând funcționalitățile și interacțiunile.</p>

                          <h4>Iterarea și îmbunătățirea designului</h4>
                          <p>Permite testarea și ajustarea rapidă a ideilor, facilitând feedback-ul și îmbunătățirea designului în stadiile incipiente ale dezvoltării.</p>

                          <h4>Economisirea timpului și a costurilor</h4>
                          <p>Identifică și rezolvă problemele în stadiile incipiente, economisind timp și costuri în etapele ulterioare ale dezvoltării.</p>

                          <h4>Aprecierea funcționalităților cheie</h4>
                          <p>Evidențiază funcționalitățile esențiale și modul în care acestea interacționează, asigurând că răspund nevoilor și așteptărilor clienților.</p>

                          <h4>Îmbunătățirea comunicării</h4>
                          <p>Servește ca punct de plecare pentru discuții și clarificări între client și echipa de dezvoltare, diminuând riscul de neînțelegeri sau interpretări greșite.</p>

                          <div class="btn theme-btn text-black" @click="setCloseAllDetailsProiect()"> ( Close ) </div>
                        </div>
                      </div>

                      <div class="para-text text-left mt-4">
                            <div v-if="showMainTitlesProiect">Software Engineer-ul va propune soluția software cea mai potrivită ca tehnologie (rapidă, eficientă, ieftină) și va crea cerințele tehnice pentru echipa de programatori si devops. <span class="btn theme-btn theme-color" @click="setShowSoftwareEngineerProiect"> ... detalii </span></div>
                        <div v-if="showSoftwareEngineerProiect" @click="setCloseAllDetailsProiect">
                          <h3>De ce este necesară analiza a proiectului de catre un Software Engineer ?</h3>
                          <div class="btn theme-btn text-black" @click="setCloseAllDetailsProiect"> ( Close ) </div>
                          <ul>
                            <li>Expertiză tehnică: Un software engineer aduce expertiză tehnică profundă în evaluarea viabilității tehnice a proiectului.</li>
                            <li>Identificarea riscurilor: Analiza realizată de un software engineer poate identifica și evalua riscurile tehnice, ajutând la evitarea problemelor majore ulterioare.</li>
                            <li>Optimizarea performanței: Prin analiza proiectului, se pot identifica oportunități de optimizare a performanței sistemului sau a codului.</li>
                            <li>Propuneri de soluții tehnice: Software engineer-ul poate oferi propuneri și soluții tehnice pentru a îmbunătăți eficiența și scalabilitatea proiectului.</li>
                            <li>Asigurarea calității: Analiza efectuată poate contribui la implementarea unor standarde ridicate de calitate în procesul de dezvoltare a software-ului.</li>
                          </ul>
                          <div class="btn theme-btn text-black" @click="setCloseAllDetailsProiect"> ( Close ) </div>
                        </div>
                      </div>
                      <div class="para-text text-left mt-4" >
                        <div v-if="showMainTitlesProiect">Project Manager-ul va duce la indeplinire impreuna cu echipa software soluția aleasă în termenul stabilit <span class="btn theme-btn theme-color" @click="setShowProjectManagerProiect"> ... detalii </span>
                        </div>

                        <div v-if="showProjectManagerProiect" @click="setCloseAllDetailsProiect">
                          <h3>Avantajele implementării proiectului software de către un Project Manager</h3>
                          <div class="btn theme-btn text-black" @click="setCloseAllDetailsProiect"> ( Close ) </div>
                          <ul>
                            <li>Planificare strategică: Project Manager-ul are o viziune amplă și ajută la stabilirea unei planificări strategice eficiente pentru proiect.</li>
                            <li>Coerență în procese: Implementarea unui Project Manager asigură o gestionare coerentă a proceselor și a resurselor pe parcursul proiectului.</li>
                            <li>Comunicare eficientă: Un Project Manager facilitează comunicarea clară și deschisă între diferitele echipe și părți implicate în proiect.</li>
                            <li>Gestionarea riscurilor și resurselor: Managerul de proiect va identifica și gestiona eficient riscurile, alocând resursele adecvate pentru a le aborda.</li>
                            <li>Monitorizare și raportare: Prin implementarea unui Project Manager, progresul proiectului poate fi monitorizat și raportat în mod regulat, menținând transparența și controlul asupra acestuia.</li>
                          </ul>
                          <div class="btn theme-btn text-black" @click="setCloseAllDetailsProiect"> ( Close ) </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="about-video-sec">
                            <div class="position-relative wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1s">
                                <img  src="assets/images/Team/group-young-business-people-working-office.jpg" class="w-100 img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Aboutus Top -->
        <!-- Start Aboutus Botttom -->
        <section class="section about-bottom bg-light" >
            <div class="container" v-if="showSteps">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6" @click="setShowAnalizaProiect">
                        <div class="mt-lg-0 mt-sm-4 mt-0">
                            <div class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="text-center">
                                    <div class="about-inner-text">
                                        <span class="icon transition mdi mdi-semantic-web theme-color f-40">
                                        </span>
                                        <h4 class="my-3 theme-color">Faza de analiză</h4>
                                      <p class="para-text mb-0">Product Managerul va colabora strâns cu clienții pentru a înțelege obiectivele și cerințele lor și va crea documente detaliate legate de specificațiile produsului, diagrame de flux și alte documente pentru a descrie în detaliu funcționalitățile și caracteristicile produsului</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6" @click="setShowWireframeProiect">
                        <div class="mt-lg-0 mt-4">
                            <div class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="text-center">
                                    <div class="about-inner-text">
                                        <span class="icon transition mdi mdi-globe-model theme-color f-40">
                                        </span>
                                        <h4 class="my-3 theme-color">Wireframe Development</h4>
                                        <p class="para-text mb-0">Prin wireframe-ul dezvoltat se va contura structura și organizarea generală a interfeței aplicației. Acesta va evidenția locația elementelor cheie: pagini, butoane, meniuri, acțiuni, secțiuni de conținut și va ajuta la clarificarea funcționalităților și a interacțiunilor disponibile.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-lg-4 col-sm-6" @click="setShowSoftwareEngineerProiect">
                        <div class="mt-lg-0 mt-4">
                            <div class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="text-center">
                                    <div class="about-inner-text">
                                        <span class="icon transition mdi mdi-rocket theme-color f-40">
                                        </span>
                                        <h4 class="my-3 theme-color"> Architectura software</h4>
                                        <p class="para-text mb-0">Software Architect va analiza pentru alegerea solutiei tehnice pentru tehnologia aleasă.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6" @click="setShowProjectManagerProiect">
                        <div class="mt-lg-0 mt-4">
                            <div class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <div class="text-center">
                                    <div class="about-inner-text">
                                        <span class="icon transition mdi mdi-rocket theme-color f-40">
                                        </span>
                                        <h4 class="my-3 theme-color">Implementare</h4>
                                      <p class="para-text mb-0">Asignarea unui Project Manager experimentat și a echipei ce va implementa tehnologica aleasă.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <div v-if="showWireframeDetails" @click="setShowWireframeDetails" class="container" style="padding: 20px;">
            <h4 class="my-3 theme-color">De ce este necesar un Wireframe?  </h4>

            <h3>Wireframe-ul este esențial pentru dezvoltarea unei aplicații web sau mobile de succes.</h3>
            <h4>Iată câteva motive pentru care este necesar și beneficiile pe care le aduce în realizarea produsului final:</h4>
            <p>
              <span class="bolder">Vizualizarea conceptului:</span> Wireframe-ul oferă o schiță sau un desen rudimentar al aplicației, permițând clientului să vizualizeze și să înțeleagă cum va arăta și cum va funcționa produsul final. Este o modalitate excelentă de a comunica și valida conceptul în stadiile incipiente ale dezvoltării.
            </p>
            <p>
              <span class="bolder">Clarificarea structurii și a funcționalităților:</span> Prin wireframe, se conturează structura și organizarea generală a interfeței aplicației. Acesta evidențiază locația elementelor cheie precum butoane, meniuri, secțiuni de conținut etc., ajutând la clarificarea funcționalităților și a interacțiunilor disponibile.
            </p>
              <p>
                <span class="bolder">Iterarea și îmbunătățirea designului:</span> Wireframe-ul permite testarea și ajustarea rapidă a ideilor. Clientul poate oferi feedback într-un stadiu incipient, permițând echipelor de design și dezvoltare să facă modificări mai ușor în etapele ulterioare ale procesului.
              </p>
            <p>
              <span class="bolder">Economisirea timpului și a costurilor:</span> Identificarea și rezolvarea problemelor într-un stadiu incipient, precum o navigare confuză sau o organizare ineficientă a informațiilor, poate economisi timp și costuri în etapele ulterioare ale dezvoltării.
            </p>

            <p>
              <span class="bolder">Aprecierea funcționalităților cheie:</span> Prin wireframe, se evidențiază funcționalitățile esențiale și modul în care acestea interacționează. Acest lucru permite clientului să înțeleagă mai bine cum vor funcționa diferitele caracteristici ale aplicației și să se asigure că acestea corespund nevoilor și așteptărilor lor.
            </p>

            <p>
              <span class="bolder">Îmbunătățirea comunicării:</span> Wireframe-ul servește ca punct de plecare pentru discuțiile ulterioare între client și echipa de dezvoltare. Acesta oferă un cadru vizual pentru a discuta și a clarifica cerințele, diminuând riscul de neînțelegeri sau interpretări greșite.
            </p>

            În general, wireframe-ul este un instrument esențial pentru a alinia așteptările între client și echipa de dezvoltare și pentru a asigura că aplicația finală va fi construită în mod eficient și în conformitate cu cerințele și nevoile clientului.
          </div>
        </section>
        <!-- End Aboutus Botttom -->
        <!-- Start Clients Section -->
    </div>
</template>

<script>
    //WOW JS
    import $ from "jquery";
    import { WOW } from 'wowjs';
    import {ref} from "vue";
    import {log} from "@/composables/useLog";
    export default {
        name: 'aboutComponent',
        mounted() {
            this.init();
        },
        methods: {
            init() {               
                this.initWowAnimation();
                this.initClientSlider();
                this.initCounter();
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },           
            /*----CLIENT SLIDER-----*/
            initClientSlider() {
                var testimonialCarousel = $('.clients-slider');
                if (testimonialCarousel.length > 0) {
                    testimonialCarousel.owlCarousel({
                        loop: true,
                        items: 1,
                        margin: 0,
                        nav: true,
                        dots: true,
                        autoplay: false,
                        autoplayTimeout: 5000,
                        responsiveClass: true,
                        responsive: {
                            0: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            600: {
                                items: 1,
                                nav: false,
                                dots: true
                            },
                            991: {
                                items: 2,
                                nav: false,
                                dots: true
                            }
                        }
                    });
                }
            },
            /*----COUNTER-----*/
            initCounter() {
                $.fn.jQuerySimpleCounter = function (options) {
                    var settings = $.extend({
                        start: 0,
                        end: 100,
                        easing: 'swing',
                        duration: 400,
                        complete: ''
                    }, options);

                    var thisElement = $(this);

                    $({
                        count: settings.start
                    }).animate({
                        count: settings.end
                    }, {
                        duration: settings.duration,
                        easing: settings.easing,
                        step: function () {
                            var mathCount = Math.ceil(this.count);
                            thisElement.text(mathCount);
                        },
                        complete: settings.complete
                    });
                };
                $('#number1').jQuerySimpleCounter({
                    end: 34,
                    duration: 2000
                });
                $('#number2').jQuerySimpleCounter({
                    end: 16,
                    duration: 2000
                });
                $('#number3').jQuerySimpleCounter({
                    end: 180,
                    duration: 2000
                });
                $('#number4').jQuerySimpleCounter({
                    end: 300,
                    duration: 2500
                });
            }
        },
      setup(){
          // alert(1)
        const showWireframeDetails=ref(false)
        const showSteps=ref(true)
        const showAnalizaProiect=ref(false)
        const showWireframeProiect=ref(false)
        const showSoftwareEngineerProiect=ref(false)
        const showProjectManagerProiect=ref(false)
        const showMainTitlesProiect=ref(true)



        const setShowWireframeDetails=()=>{
          showWireframeDetails.value=!showWireframeDetails.value
          showSteps.value=!showSteps.value
        }
        const setHideAllProiect=()=>{
          log("welcome to setHideAllProiect")
          showAnalizaProiect.value=false
          showWireframeProiect.value=false
          showSoftwareEngineerProiect.value=false
          showProjectManagerProiect.value=false
          showMainTitlesProiect.value=false
        }

        const setShowAllTitlesProiect=()=>{
          log("setShowAllTitlesProiect")
          showMainTitlesProiect.value=true
        }
        const setCloseAllDetailsProiect=async ()=>{
          log("welcome in setCloseAllDetailsProiect")
          await setHideAllProiect()
          showMainTitlesProiect.value=true
        }


        const setShowAnalizaProiect=()=>{
          setHideAllProiect()
          showAnalizaProiect.value=true
        }
        const setShowWireframeProiect=()=>{
          setHideAllProiect()
          showWireframeProiect.value=true
        }

        const setShowSoftwareEngineerProiect=()=>{
          setHideAllProiect()
          showSoftwareEngineerProiect.value=true
        }
        const setShowProjectManagerProiect=()=>{
          setHideAllProiect()
          showProjectManagerProiect.value=true
        }
        return{
          showWireframeDetails,
          setShowWireframeDetails,
          showSteps,
          setShowAnalizaProiect,
          showAnalizaProiect,
          setShowWireframeProiect,
          showWireframeProiect,
          setShowSoftwareEngineerProiect,
          showSoftwareEngineerProiect,
          setShowProjectManagerProiect,
          showProjectManagerProiect,
          setHideAllProiect,
          setShowAllTitlesProiect,
          setCloseAllDetailsProiect,
          showMainTitlesProiect
        }
      }
    }
</script>