import {ref} from "vue";
const BASE_URL='https://api.rcharge.ro' 
const JUST4WEB= false
// const BASE_URL='http://localhost:3400'
// const BASE_URL='http://api.rcharge.ro:3400'
const WS_URL="wss://server.rcharge.ro:9002"
const sandboxRedirectionUrl = ref( 'https://sandboxsecure.mobilpay.ro')
const secureRedirectionUrl = ref( 'https://secure.mobilpay.ro')
const FACEBOOK_APP_ID='590253342582282'
export {BASE_URL,sandboxRedirectionUrl, secureRedirectionUrl, WS_URL, FACEBOOK_APP_ID, JUST4WEB}
