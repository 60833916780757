<template>
    <div >
        <!-- Home Start-->
        <section class="main-header bg-header" style="margin-top:0px;">
            <div class="home-bg-img">
                <div class="container">
                    <div class="owl-carousel1 owl-theme main-slider">
                        <div class="item1">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <div class="content wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1s">
                                        <h2 class="banner-title text-lg-left text-center text-white text-uppercase">
                                            DEZVOLTAȚI-VĂ AFACEREA<br>CU NOI
                                        </h2>
                                        <div class=" top30 text-white text-lg-left text-center para-txt font-weight-medium f-16">
                                            Doriți să dezvoltați un proiect IT? Noi suntem consultanții care vă ajută să devină realitate.
                                          <h2 class="top30">Aplicații WEB</h2>
                                          <h2>Solutii integrate IT</h2>
                                          <h3>Soluții software</h3>
                                          <h2>Aplicații pentru telecomunicații</h2>

                                        </div>
                                      <router-link class="nav-link text-white transition text-uppercase" to="/about">
                                        <div class="mt-md-4 mb-4 text-lg-left text-center">
                                            <a href="" class="btn theme-btn text-white">GET STARTED</a>
                                        </div>
                                      </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="banner-img-02 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                        <img src="assets/images/programmer_professional_illustration.jpg" class="img-fluid" alt="">
<!--                                      image-->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import $ from "jquery";
import { WOW } from 'wowjs';
import {setLocalImageUrl} from '@/composables/useLog'
    //OWL CAROUSEL JS
    import 'owl.carousel/dist/assets/owl.carousel.css';
    import 'owl.carousel';
import {onMounted} from "vue";
    // import {formatDate, log, setLocalImageUrl} from "@/composables/useLog"
    export default {
      name: 'indexComponent',
      components: {},
      setup() {

        onMounted(()=>{
          init()
        })

        const init=()=> {
          initWowAnimation();
        }
        /*----WOW ANIMATION-----*/
        const initWowAnimation=()=>{
          var length = $('.wow').length;
          // alert(length)
          if (length >= 1) {
            const wow = new WOW({
              boxClass: 'wow',
              animateClass: 'animated',
              offset: 0,
            });
            wow.init();
          }
        }
        return {
          setLocalImageUrl
        }
      }
    }
</script>

<style>

</style>