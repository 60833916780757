import { createApp } from 'vue'
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.vue'
import router from './router'
import store from './store'
import * as jqueryExports from "jquery";
window.$ = jqueryExports.default;

createApp(App).use(store).use(router).mount('#app')
