import { createRouter, createWebHistory } from 'vue-router'

import indexComponent from "@/components/indexComponent.vue";
import aboutComponent from "@/components/aboutComponent.vue";
import servicesComponent from "@/components/servicesComponent.vue";
import blogComponent from "@/components/blogComponent.vue";
import blogdetailComponent from "@/components/blogdetailComponent.vue";
import errorComponent from "@/components/errorComponent.vue";
import loginComponent from "@/components/loginComponent.vue";
import signupComponent from "@/components/signupComponent.vue";
import contactComponent from "@/components/contactComponent.vue";
import faqComponent from "@/components/faqComponent.vue";

const routes= [
  {
    path: '/',
    name: 'index',
    component: indexComponent
  },
  {
    path: '/about',
    name: 'about',
    component: aboutComponent
  },
  {
    path: '/services',
    name: 'services',
    component: servicesComponent
  },

  {
    path: '/blog',
    name: 'blog',
    component: blogComponent
  },
  {
    path: '/blogdetail',
    name: 'blogdetail',
    component: blogdetailComponent
  },
  {
    path: '/error',
    name: 'error',
    component: errorComponent
  },
  {
    path: '/login',
    name: 'login',
    component: loginComponent
  },
  {
    path: '/signup',
    name: 'signup',
    component: signupComponent
  },
  {
    path: '/contact',
    name: 'contact',
    component: contactComponent
  },
  {
    path: '/faq',
    name: 'faq',
    component: faqComponent

  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
