<template>
    <div>
        <!-- BradCrumb header Start-->
        <section class="inner-header-bg">
            <div class="container">
                <!-- Breadcrumb Start-->
                <div class="section-breadcrumb">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 justify-content-center align-self-center pb-2">
                                <div class="breadcrumb-content">
                                    <div class="title-section">
                                        <h1 class="title text-center text-white">Frequently Asked Questions</h1>
                                    </div>
                                    <div class="breadcrumb-nav">
                                        <ul class="breadcrumb-link text-center list-unstyled mb-0">
                                            <li class="text-white"><a href="index.html">Home</a></li>
                                            <li class="text-white active">FAQ</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Breadcrumb End -->
            </div>
        </section>
        <!-- BradCrumb header End -->
        <!-- Start FAQ's And Meet Our Team -->
        <section class="section">
            <div class="container">
                <div class="row align-items-baseline">
                    <div class="col-lg-12 wow fadeInUp" data-wow-duration="1500ms">
                        <div class="title-section">
                            <div class="subtitle-hr d-flex align-items-center">
                                <h6 class="subtitle theme-color text-uppercase position-relative">FAQ's</h6>
                                <hr>
                            </div>
                            <h2 class="title text-md-left text-center f-40">Ask Question?</h2>
                        </div>
                        <div class="mt-4">
                            <div id="accordion" class="wow fadeInUp" data-wow-duration="1500ms">
                                <div class="accordion-card bg-white mb-4 shadow rounded active">
                                    <div class="card-header border-0 p-0" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span class="font-weight-medium"> Made With Love</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                                It is a long established fact that a reader will be distracted by the read able content of a page when looking at its layout.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-card bg-white mb-4 shadow rounded">
                                    <div class="card-header border-0 p-0" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span class="font-weight-medium"> Made With Love?</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                                It is a long established fact that a reader will be distracted by the read able content of a page when looking at its layout.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-card bg-white mb-4 shadow rounded">
                                    <div class="card-header border-0 p-0" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span class="font-weight-medium"> Made With Love?</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                                It is a long established fact that a reader will be distracted by the read able content of a page when looking at its layout.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-card bg-white mb-4 shadow rounded">
                                    <div class="card-header border-0 p-0" id="headingFour">
                                        <h5 class="mb-0">
                                            <button class="link-btn p-0 text-left border-0 bg-white w-100 d-flex justify-content-between align-items-center theme-color collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span class="font-weight-medium"> Made With Love?</span><span><i class="mdi mdi-chevron-down d-flex f-30"></i></span></button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div class="pt-2">
                                            <p class="m-0">
                                                It is a long established fact that a reader will be distracted by the read able content of a page when looking at its layout.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End FAQ's -->
    </div>
</template>
<script>
    import $ from "jquery";
    import { WOW } from 'wowjs';

    export default {
        name: 'faqComponent',
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initWowAnimation();    
                this.initAccordian();
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            initAccordian() {
                $(".accordion-card").click(function () {
                    if ($(this).hasClass('active')) {
                        $('.accordion-card').removeClass('active');
                        $(this).removeClass('active')
                    } else {
                        $('.accordion-card').removeClass('active');
                        $(this).addClass('active')
                    }
                });
            }
        }
    }
</script>